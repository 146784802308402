import React, { useEffect, useState, useContext } from 'react';
import Transactions from 'pages/Transactions/Transactions';
import {
  Col,
  Row,
  FormGroup,
  Badge,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Accordion,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr';
import moment from 'moment-timezone';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import DriverDetail from 'pages/CRM/Drivers/DriverDetail/DriverDetail';
import { getLabelCurrency } from 'helpers/Currencies';
import SearchInput from 'Components/Common/SearchInput';
import SelectBox from 'Components/Common/SelectBox';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import ExportBlock from 'laxyExcel/ExportComponents';
import { useTranslation } from 'react-i18next';
import {
  TransactionType,
  DriverType,
  LocationType,
  ChargeStationType,
} from 'declerations/DefaultTypes';
import TransactionStatusBadge from 'Components/Common/TransactionStatusBadge';
import {
  TransactionStatusColorEnum,
  handleTransactionStatus,
} from 'helpers/Transaction';

const DriverTransactions = (props: { driver: DriverType }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext(DataContext);
  const applicationContext = useContext(ApplicationContext);
  const [filter, setFilter] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
    applicationId: string | undefined;
    locationId: string | undefined;
    chargePointId: string | undefined;
    state: string | undefined;
    user: string | undefined;
    ocppTagId: string | undefined;
  }>({
    applicationId: applicationContext.selectedApplication?._id || 'null',
    locationId: 'null',
    chargePointId: 'null',
    state: 'null',
    user: 'null',
    endDate: 'null',
    startDate: 'null',
    ocppTagId: props.driver.ocppTagId,
  });
  const [filtered, setFiltered] = useState('');
  const [selectedRangeType, setSelectedRangeType] = useState('all');
  const [rangeDate, setRangeDate] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpened, setfilterOpened] = useState('0');
  const [applications, setApplications] = useState([]);
  const [isDriverDetailOpen, setisDriverDetailOpen] = useState(false);
  const [driver, setDriver] = useState<DriverType[] | undefined>();

  useEffect(() => {
    context
      .LoadDriverTransactions({ ...filter, applicationId: 'null' })
      .then(() => {
        return context.LoadApplications();
      })
      .then(() => {
        context.LoadLocations({ applicationId: 'null' });
        context.LoadChargeStations({ ...filter, applicationId: 'null' });
        setLoading(false);
      })
      .then(() => {
        context.LoadDrivers();
      });
  }, [filter]);

  const search = (rowData: TransactionType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: TransactionType) =>
        row?.chargePointId?.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1 ||
        row?.connectorSocketType
          ?.toLowerCase()
          .indexOf(filtered.toLowerCase()) > -1 ||
        (row.consumer &&
          `${row?.consumer?.fname?.toLowerCase()} ${row?.consumer?.lname?.toLowerCase()}`.indexOf(
            filtered.toLowerCase()
          ) > -1) ||
        (row.consumer &&
          row?.consumer?.email?.toLowerCase().indexOf(filtered.toLowerCase()) >
            -1) ||
        (row.idTag &&
          row?.idTag?.toLowerCase().indexOf(filtered.toLowerCase()) > -1) ||
        (row.transactionId &&
          row?.transactionId
            ?.toString()
            .toLowerCase()
            .indexOf(filtered.toLowerCase()) > -1) ||
        (row?.idTagName &&
          row?.idTagName?.toLowerCase().indexOf(filtered.toLowerCase()) > -1)
    );
  };
  const changeSelectedRangeType = (type: string) => {
    const baseDate = new Date();
    let startDate: Date | string = new Date();
    let endDate: Date | string = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() + 1
    );
    if (type === 'today') {
      startDate.setHours(0, 0, 0, 0);
      endDate = moment(rangeDate[1]).toDate();
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisWeek') {
      startDate = moment().startOf('week').toDate();
      startDate.setHours(0, 0, 0, 0);
      endDate = moment().endOf('week').add(1, 'days').toDate();
      endDate.setHours(23, 59, 59, 999);
      const startDateDay = startDate.toLocaleDateString('en-US', {
        weekday: 'long',
      });

      if (startDateDay === 'Sunday') {
        startDate = moment(startDate).add(1, 'days').toDate();
        endDate = moment(endDate).add(-1, 'days').toDate();
      }
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisMonth') {
      startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisYear') {
      startDate = new Date(baseDate.getFullYear(), 1, 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'customDate') {
      startDate = moment(rangeDate[0]).toDate();
      endDate = moment(rangeDate[1]).add(1, 'days').toDate();
      startDate.setHours(0, 0, 0, 0);
      endDate = moment(rangeDate[1]).toDate();
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'all') {
      setFilter({ ...filter, endDate: 'null', startDate: 'null' });
    }
    setSelectedRangeType(type);
  };

  useEffect(() => {
    if (selectedRangeType === 'customDate') {
      changeSelectedRangeType('customDate');
    }
  }, [rangeDate]);

  const changeFilter = (filterType: string, value: string) => {
    switch (filterType) {
      case 'applicationId':
        setFilter({
          ...filter,
          applicationId: value,
          locationId: 'null',
          chargePointId: 'null',
        });
        break;
      case 'locationId':
        setFilter({ ...filter, locationId: value, chargePointId: 'null' });
        break;
      case 'chargePointId':
        setFilter({ ...filter, chargePointId: value });
        break;
      case 'user':
        setFilter({ ...filter, user: value });
        break;
      case 'state':
        setFilter({ ...filter, state: value });
        break;
      case 'ocppTagId':
        setFilter({ ...filter, ocppTagId: value });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (filter.ocppTagId !== props.driver.ocppTagId)
      changeFilter('ocppTagId', props.driver.ocppTagId);
  }, [props.driver.ocppTagId]);

  const findDriver = (transaction: TransactionType) => {
    const consumer = context.drivers?.find(
      (x: DriverType) => x.ocppTagId === transaction.idTag
    );
    if (!consumer) {
      return null;
    }
    return `${consumer.fname} ${consumer.lname}`;
  };

  const mappers = {
    status: (row: any) => {
      if (row.isRefunded) {
        return t('Transaction.refunded');
      }
      if (row.inProgress) {
        return t('Transaction.inProgress');
      }
      if (row.paymentProfile?.paymentStatus === 'FAILED') {
        return t('Transaction.notPaid');
      }
      return t('Transaction.paid');
    },
    driver: (row: any) => {
      return findDriver(row);
    },
  };
  const headers = [
    'status',
    'startDate',
    'endDate',
    'driver',
    'chargePointId',
    'transactionId',
    'paymentProfile.totalFee',
    'paymentProfile.currency',
  ].map((key: string) => ({
    key,
    value: `transactions.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));
  return props?.driver?.ocppTagId ? (
    <>
      <Row className='mb-1'>
        <Col>
          <Accordion open={filterOpened}>
            <AccordionItem>
              <AccordionHeader
                targetId='1'
                onClick={() =>
                  setfilterOpened(filterOpened === '1' ? '0' : '1')
                }
              >
                <Row className='w-100 py-0'>
                  <Col xl={4} sm={12} xs={12} className='d-flex  pb-0'>
                    <SearchInput
                      isFilter
                      onFocus={() => setfilterOpened('1')}
                      onChange={(text) => {
                        setFiltered(text);
                      }}
                    />
                  </Col>
                </Row>
              </AccordionHeader>
              <AccordionBody accordionId='1'>
                <Row>
                  <Col xl={2} sm={12}>
                    <Row>
                      <Col>
                        <FormGroup className='m-0'>
                          <label
                            style={{
                              color: 'rgba(0,0,0,0.87)',
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                            htmlFor='date-range'
                          >
                            {t('Transaction.chooseDateRange')}
                          </label>
                          <select
                            id='date-range'
                            className='form-control form-select mb-1'
                            value={selectedRangeType}
                            onChange={(e) =>
                              changeSelectedRangeType(e.target.value)
                            }
                          >
                            <option style={{ color: 'black' }} value='all'>
                              {t('Transaction.all')}
                            </option>
                            <option style={{ color: 'black' }} value='today'>
                              {t('Transaction.today')}
                            </option>
                            <option style={{ color: 'black' }} value='thisWeek'>
                              {t('Transaction.thisWeek')}
                            </option>
                            <option
                              style={{ color: 'black' }}
                              value='thisMonth'
                            >
                              {t('Transaction.thisMonth')}
                            </option>
                            <option style={{ color: 'black' }} value='thisYear'>
                              {t('Transaction.thisYear')}
                            </option>
                            <option
                              style={{ color: 'black' }}
                              value='customDate'
                            >
                              {t('Transaction.customDate')}
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    {selectedRangeType === 'customDate' && (
                      <Row>
                        <Col xl={12} sm={12} className='text-align'>
                          <FormGroup className='m-0'>
                            <label
                              style={{
                                color: 'rgba(0,0,0,0.87)',
                                fontWeight: '600',
                                fontSize: '14px',
                              }}
                              htmlFor='range-picker'
                            >
                              {t('Transaction.chooseDateRange')}
                            </label>
                            <Flatpickr
                              value={rangeDate}
                              id='range-picker'
                              className='form-control'
                              onChange={(date) => {
                                if (date.length > 0) {
                                  setRangeDate(date);
                                }
                              }}
                              options={{
                                mode: 'range',
                                maxDate: new Date(),
                                locale:
                                  t('Transaction.locale') === 'Turkish'
                                    ? Turkish
                                    : undefined,
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Col>

                  <Col xl={2} sm={12} xs={12}>
                    <SelectBox
                      value={filter.locationId}
                      label={t('Transaction.location')}
                      onChange={(value) => changeFilter('locationId', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      {context.locations &&
                        context.locations.length > 0 &&
                        context.locations.map((item: LocationType) => (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        ))}
                    </SelectBox>
                  </Col>
                  <Col xl={2} sm={12} xs={12}>
                    <SelectBox
                      value={filter.chargePointId}
                      label={t('Transaction.chargePoint')}
                      onChange={(value) => changeFilter('chargePointId', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      {context.chargeStations &&
                        context.chargeStations.length > 0 &&
                        context.chargeStations.map(
                          (item: ChargeStationType) => (
                            <option
                              key={item.chargePointId}
                              value={item.chargePointId}
                            >
                              {item.name}
                            </option>
                          )
                        )}
                    </SelectBox>
                  </Col>
                  <Col xl={2} sm={12} xs={12}>
                    <SelectBox
                      value={filter.user}
                      label={t('Transaction.user')}
                      onChange={(value) => changeFilter('user', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      <option value='Customer'>
                        {t('Transaction.customer')}
                      </option>
                      <option value='CPO'>{t('Transaction.Cpo')}</option>
                    </SelectBox>
                  </Col>
                  <Col xl={2} sm={12}>
                    <SelectBox
                      value={filter.state}
                      label={t('ChargeStations.state')}
                      onChange={(value) => changeFilter('state', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      <option value='Charging'>
                        {t('Transaction.charging')}
                      </option>
                      <option value='Completed'>
                        {t('Transaction.completed')}
                      </option>
                      <option value='Paid'>{t('Transaction.paid')}</option>
                      <option value='Unpaid'>{t('Transaction.unpaid')}</option>
                    </SelectBox>
                  </Col>
                  <Col
                    xl={2}
                    sm={12}
                    xs={12}
                    className='justify-content-end '
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <ExportBlock
                      data={context.driverTransactions}
                      header={headers}
                      name={t('Transactions')}
                    />
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} sm={12}>
          <DataTable
            loading={loading}
            pointerOnHover
            noDataText={t('Transaction.thereareNoTransactionCreated')}
            onRowClicked={(row, event) => {}}
            columns={[
              {
                name: t('Transaction.status'),
                grow: 1.4,
                cell: (row: any) => {
                  const { title, color } = handleTransactionStatus(row);

                  return (
                    <h5>
                      <TransactionStatusBadge color={color}>
                        {t(title)}
                      </TransactionStatusBadge>
                    </h5>
                  );
                },
              },
              {
                name: t('Transaction.startTime'),
                selector: (row: TransactionType) => row.startDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row?.timezone
                    ? moment(row?.startDate)
                        .tz(row?.timezone)
                        .format('lll')
                        .toString()
                    : moment(row.startDate).format('lll').toString(),
              },
              {
                name: t('Transaction.endTime'),
                selector: (row: TransactionType) => row.endDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row.inProgress
                    ? '-'
                    : row?.timezone
                    ? moment(row.endDate)
                        .tz(row.timezone)
                        .format('lll')
                        .toString()
                    : moment(row.endDate).format('lll').toString(),
              },
              {
                name: t('Transaction.driver'),
                selector: (row: TransactionType) => row.idTag,
                cell: (row: TransactionType) => {
                  const consumer = context.drivers?.find(
                    (x: DriverType) => x.ocppTagId === row.idTag
                  ) || { fname: '', lname: '' };
                  if (!consumer) {
                    return null;
                  }
                  const title = `${consumer.fname} ${consumer.lname}`;
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href='#'
                      aria-hidden='true'
                      onClick={(e) => {
                        e.preventDefault();
                        // editContext.SetEditDriver(consumer);
                        // setisDriverDetailOpen(true);
                        // history.push(`/drivers/${consumer._id}`, {
                        //   driver: consumer,
                        // });
                      }}
                    >
                      {title}
                    </a>
                  );
                },
              },
              {
                name: t('Transaction.plateNo'),
                selector: (row: any) =>
                  row.consumer?.vehicle?.plateNo
                    ? row.consumer?.vehicle?.plateNo
                    : '-',
              },
              {
                name: t('Transaction.CP Id'),
                selector: (row: TransactionType) => row.chargePointId,
              },
              {
                name: t('Transaction.transactionId'),
                selector: (row: TransactionType) => row.transactionId,
              },
              {
                name: t('Transaction.tagName'),
                selector: (row: TransactionType) => row.idTagName,
                cell: (row: TransactionType) =>
                  row?.idTagName != null ? row.idTagName : row.idTag,
              },
              {
                name: t('Transaction.totalFee'),
                sortable: true,
                selector: (row: TransactionType) => row.paymentProfile.totalFee,
                cell: (row: TransactionType) => (
                  <div className='text-primary'>
                    {row.paymentProfile && row.paymentProfile.totalFee
                      ? `${row.paymentProfile.totalFee.toFixed(
                          2
                        )} ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`
                      : `0 ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`}
                  </div>
                ),
              },
            ]}
            data={search(context.driverTransactions) || []}
          />
        </Col>
      </Row>
    </>
  ) : (
    // <Transactions ocppTagId={props.driver.ocppTagId} />
    <Row>
      <Col>
        <div className='d-flex justify-content-center mb-0'>
          {t('DriverPaymentInfo.ThereisnoRegisteredCard')}
        </div>
      </Col>
    </Row>
  );
};

export default DriverTransactions;