/* eslint-disable @typescript-eslint/naming-convention */ 
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { DOMAIN_KEY, TENANTS_V2 } from 'helpers/config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon as FeatherIcon } from 'ts-react-feather-icons';

const NavigateV2Alert = ({
  setIsV2AlertVisible,
}: {
  setIsV2AlertVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isV2PreviewAvailable = TENANTS_V2.includes(DOMAIN_KEY);
  const [redirectURL, setRedirectURL] = useState<string>('');

  const [isAlertVisible, setIsAlertVisible] =
    useState<boolean>(isV2PreviewAvailable);
  const { t } = useTranslation();

  const handleRedirectUrl = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
    let [_, env] = window.location.hostname.split('.');
    const [tenant, ...other] = window.location.hostname.split('.');

    if (!env) env = '';

    if (DOMAIN_KEY === 'devtest' || DOMAIN_KEY === 'staging') {
      setRedirectURL(`https://${DOMAIN_KEY}.${env ? `${env}.` : ''}lahar.io`);
    } else {
      setRedirectURL(`https://${tenant}.v2.${other.join('.')}`);
    }
  };

  useEffect(() => {
    if (!isAlertVisible) {
      setIsV2AlertVisible(false);
    } else {
      setIsV2AlertVisible(true);
    }
  }, [isAlertVisible]);
  useEffect(() => {
    if (sessionStorage.getItem('isAlertClosedBefore')) {
      setIsV2AlertVisible(false);
      setIsAlertVisible(false);
    }
    handleRedirectUrl();
  }, []);

  const onClose = (): void => {
    sessionStorage.setItem('isAlertClosedBefore', 'true');
    setIsAlertVisible((prev: boolean) => !prev);
  };

  return (
    <>
      {isAlertVisible && isV2PreviewAvailable ? (
        <div className='alert-navigate-v2-container'>
          <div></div>
          <a className='alert-navigate-v2-link' href={redirectURL}>
            {t('navigateV2Alert.linkText')}
          </a>
          <div onClick={onClose} className='alert-navigate-v2-close'>
            <FeatherIcon size={16} name='x' />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NavigateV2Alert;
