/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  Label,
} from 'reactstrap';
import Toggle from 'react-toggle';
import * as Icons from 'react-feather';
import { toast } from 'react-toastify';
import DataTable from 'Components/Common/DataTable';
import { useHistory } from 'react-router-dom';
import DataContext from 'Components/Contexts/DataContext';
import Container from 'Components/Common/Container';
import SearchInput from 'Components/Common/SearchInput';
import {
  deleteChargeStation,
  editChargeStation,
  getChargeStations,
} from 'actions';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import { ChargeStationType } from 'declerations/DefaultTypes';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditContext from 'Components/Contexts/EditContext';
import getSocketSvg from 'helpers/Socket';
import ExportBlock from 'laxyExcel/ExportComponents';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { ChargeStationsDetails } from './ChargeStationDetails/ChargeStationsDetails';
import AddChargeStation from './AddChargeStation';
import EditChargeStation from './AddChargeStation/EditChargeStations';

const ChargeStations = () => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);

  const context = useContext(DataContext);
  const applicationContext = useContext(ApplicationContext);
  const editContext = useContext(EditContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [filtered, setFiltered] = useState('');
  const [filter, setFilter] = useState<{
    applicationId: string | undefined;
    limit?: number;
    offset?: number;
  }>({
    applicationId: undefined,
  });
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [PerRows, setPerRows] = useState<number>(30);
  const [reset, setReset] = useState<boolean>(false);
  const [data, setData] = useState<ChargeStationType[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState<any>(
    applicationContext.selectedApplication?._id
  );
  const locAllCPO = localStorage.getItem('allCPO');

  const mappers = {
    connectors: (row: any) => {
      const ee: any = [];
      row.connectors.map((e: any) => ee.push(e.level === "Level 2" ? "AC" : e.level));
      return ee;
    },
    uptime: (row: any) => {
      if (row?.uptime) {
        return `${row?.uptime}`;
      }
      return `-`;
    },
    chargePointModel: (row: any) => {
      if (row?.chargePointModel) {
        return `${row?.chargePointModel}`;
      }
      return `-`;
    },
    chargePointSerialNumber: (row: any) => {
      if (row?.chargePointSerialNumber) {
        return `${row?.chargePointSerialNumber}`;
      }
      return `-`;
    },
    chargePointVendor: (row: any) => {
      if (row?.chargePointVendor) {
        return `${row?.chargePointVendor}`;
      }
      return `-`;
    },
    cityCode: (row: any) => {
      return row?.address?.cityCode ? row?.address?.cityCode : '-';
    },
    county: (row: any) => {
      return row?.address?.county ? row?.address?.county : '-';
    },
  };

  const AutoRefreshComponent = (
    <AutoRefresh
      isActive={isActiveAutoRefresh}
      onPress={() => {
        setIsActiveAutoRefresh(!isActiveAutoRefresh);
      }}
    />
  );

  const headers = [
    'chargePointId',
    'name',
    'uptime',
    'connectors',
    'cityCode',
    'county',
    'chargePointVendor',
    'chargePointModel',
    'chargePointSerialNumber',
  ].map((key) => ({
    key,
    value: `chargePoints.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));

  const loadDataTable = () => {
    if (!applicationContext.selectedApplication?._id) {
      getChargeStations({ ...filter, applicationId: '', limit: PerRows }).then(
        (res: any) => {
          setData(res.data.chargePoints);
          setCount(res.data.count);
          setLoading(false);
        }
      );
    } else {
      // if (!selectedApplication && locAllCPO) return;
      getChargeStations({
        ...filter,
        applicationId: selectedApplication,
        limit: PerRows,
      })
        .then((res) => {
          setData(res.data.chargePoints);
          setCount(res.data.count);
          setLoading(false);
        })
        .then(() => {
          context.LoadLocations({
            applicationId: selectedApplication,
          });
        });
    }
    if (isFirstLoad) setIsFirstLoad(false);
  };

  const pagination = (e: number) => {
    setPageNumber(e);
    const control = count / PerRows;
    const flag = Math.ceil(control);
    if (e <= flag) {
      setFilter({
        ...filter,
        offset: PerRows && (e - 1) * PerRows,
        limit: PerRows,
      });
    } else if (e === flag) {
      setFilter({
        ...filter,
        offset: count,
        limit: PerRows,
      });
    }
  };
  const handlePerRowsChange = (e: number) => {
    setPageNumber(0);
    setPerRows(e);
    setReset(!reset);
    setFilter({
      ...filter,
      offset: 0,
      limit: e,
    });
  };

  useEffect(() => {
    loadDataTable();
  }, [filter]);

  useEffect(() => {
    if (isFirstLoad) return;

    if (selectedApplication) {
      setFilter({
        ...filter,
        applicationId: selectedApplication,
      });
    } else {
      setFilter({
        ...filter,
        applicationId: '',
      });
    }
  }, [selectedApplication]);

  useEffect(() => {
    if (isFirstLoad) return;
    setSelectedApplication((prev: any) => {
      if (prev !== applicationContext.selectedApplication?._id) {
        return applicationContext.selectedApplication?._id;
      }
      return prev;
    });
  }, [applicationContext.selectedApplication]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);

    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, applicationContext.selectedApplication, filter]);

  const search = (rowData: ChargeStationType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: ChargeStationType) =>
        row?.name?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.chargePointId?.toLowerCase().indexOf(filtered.toLowerCase()) > -1
    );
  };
  const openFullDetailPage = (selectedChargeStation: ChargeStationType) => {
    history.push(`/cpo/charge-station/${selectedChargeStation.chargePointId}`);
  };

  // eslint-disable-next-line consistent-return
  function checkDisable(item: any) {
    item?.transactionState === 'Active';
    for (let i = 0; i < item.length; i += 1) {
      if (item[i].transactionState === 'Active') {
        return true;
      }
    }
  }

  return (
    <Container title={t('Charge Stations')} rightSide={AutoRefreshComponent}>
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className='d-flex'>
          <SearchInput onChange={(text) => setFiltered(text)} />
        </Col>
        <Col xl={3} sm={12} />
        <Col xl={3} sm={12} />
        <Col
          xl={3}
          sm={12}
          className='d-flex justify-content-end '
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ExportBlock
            data={data}
            header={headers}
            name={t('Charge Station')}
          />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setAddIsOpen(!addIsOpen);
            }}
            disabled={!applicationContext.selectedApplication}
          >
            <Icons.Plus className='me-2 p-0' size='20' />
            {t('Add')}
          </Button>
        </Col>
      </Row>
      <DataTable
        paginationServer
        paginationTotalRows={count}
        paginationPerPage={PerRows}
        onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
        onChangePage={(e) => pagination(e)}
        paginationResetDefaultPage={reset}
        pointerOnHover
        onRowClicked={(row) => {
          openFullDetailPage(row);
          // setIsOpen(true);
        }}
        loading={loading}
        noDataText={t('ChargeStations.thereArenoChargeStationCreated')}
        columns={[
          {
            sortable: false,
            maxWidth: '32px',
            width: '32px',
            minWidth: '32px',
            cell: (row: ChargeStationType) => {
              return (
                <div>
                  <span
                    id={`chargeStation${row._id}`}
                    style={{ padding: 8, cursor: 'pointer' }}
                  >
                    <span
                      style={{
                        padding: 4,
                        display: 'inline-block',
                        backgroundColor: (() => {
                          if (!row.isOnline) {
                            return '#dd3333';
                          }
                          return '#22dd22';
                        })(),
                        borderRadius: '50%',
                      }}
                    />
                  </span>
                  <UncontrolledTooltip
                    placement='top'
                    target={`chargeStation${row._id}`}
                  >
                    {t(
                      `ChargeStations.${(() => {
                        if (!row.isOnline) {
                          return 'chargeStationOffline';
                        }
                        return 'chargeStationOnline';
                      })()}`
                    )}
                  </UncontrolledTooltip>
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.CS Id'),
            selector: (row: ChargeStationType) => row.chargePointId,
            sortable: true,
            cell: (row: ChargeStationType) => {
              return (
                <div>
                  <span id={`CSId${row._id}`}>{row.chargePointId}</span>
                  <UncontrolledTooltip
                    placement='top'
                    target={`CSId${row._id}`}
                  >
                    {row.chargePointId}
                  </UncontrolledTooltip>
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.name'),
            selector: (row: ChargeStationType) => row.name,
            sortable: true,
            cell: (row: ChargeStationType) => {
              return (
                <div>
                  <span id={`CSname${row._id}`}>{row.name}</span>
                  <UncontrolledTooltip
                    placement='top'
                    target={`CSname${row._id}`}
                  >
                    {row.name}
                  </UncontrolledTooltip>
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.uptime'),
            selector: (row: ChargeStationType) =>
              `${row.uptime ? `${row.uptime}%` : 'N/A'}`,
            sortable: true,
          },
          {
            name: t('ChargeStations.connectors'),
            cell: (props: ChargeStationType) => {
              const { connectors } = props;
              return connectors && connectors.length > 0 ? (
                <div>
                  {connectors.map((x, index) => (
                    <span
                      key={index.toString()}
                      className='m-1'
                      id={`connector${x._id}`}
                    >
                      {getSocketSvg(
                        x.socketType,
                        (() => {
                          if (x.status && x.status.status) {
                            switch (x.status.status) {
                              case 'Available':
                                return '#44aa44';
                              case 'Preparing':
                                return '#44aa44';
                              case 'Finishing':
                                return '#44aa44';
                              case 'Reserved':
                                return '#44aa44';
                              case 'SuspendedEvse':
                                return '#dd3333';
                              case 'SuspendedEV':
                                return '#dd3333';
                              case 'Unavailable':
                                return '#dd3333';
                              case 'Faulted':
                                return '#dd3333';
                              case 'Charging':
                                return '#ccbb44';
                              default:
                                return '#dd3333';
                            }
                          }
                          if (!x.isOnline) {
                            return '#dd3333';
                          }
                          if (x.transactionState === 'Active') {
                            return '#ccbb44';
                          }
                          return '#44aa44';
                        })()
                      )}

                      <UncontrolledTooltip
                        placement='top'
                        target={`connector${x._id}`}
                      >
                        {x.status && x.status.status
                          ? x.status.status === 'Faulted'
                            ? `${x.socketType} ${x.status.status} ${x.status.vendorErrorCode}`
                            : `${x.socketType} ${x.status.status}`
                          : `${x.socketType} ${t(
                              `ChargeStations.${(() => {
                                if (!x.isOnline) {
                                  return 'connectorOffline';
                                }
                                if (x.transactionState === 'Active') {
                                  return 'connectorCharging';
                                }
                                return 'connectorAvailable';
                              })()}`
                            )}`}
                      </UncontrolledTooltip>
                    </span>
                  ))}
                </div>
              ) : (
                <div />
              );
            },
          },

          {
            name: t('ChargeStations.enable'),
            cell: (props: ChargeStationType) => {
              return (
                <div className='m-atuo align-middle d-flex'>
                  <Toggle
                    icons={false}
                    // defaultChecked={!props.isBlocked}
                    checked={!props.isBlocked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        MySwal.fire({
                          title: t(
                            'ChargeStations.doYouWanttoenableThisChargeStation'
                          ),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            editChargeStation(props.chargePointId, {
                              isBlocked: !!e.target.checked,
                            })
                              .then(() => {
                                loadDataTable();
                                toast.success(t('ChargeStations.toastSucces'));
                              })
                              .catch(() =>
                                toast.error(t('ChargeStations.toastErr'))
                              );
                          }
                        });
                      } else {
                        MySwal.fire({
                          title: t(
                            'ChargeStations.doYouWanttodisableThisChargeStation'
                          ),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            editChargeStation(props.chargePointId, {
                              isBlocked: e.target.checked,
                            })
                              .then(() => {
                                loadDataTable();
                                toast.success(t('ChargeStations.toastSucces'));
                              })
                              .catch(() =>
                                toast.error(t('ChargeStations.toastErr'))
                              );
                          }
                        });
                      }
                    }}
                    className='switch-danger'
                  />
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.chargesoftMap'),

            cell: (props: ChargeStationType) => {
              return (
                <div className='m-atuo align-middle d-flex'>
                  <Toggle
                    icons={false}
                    checked={props.showExternal}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        MySwal.fire({
                          title: t(
                            'ChargeStations.doYouWanttoTurnOffitsVisibilityonTheMap'
                          ),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            editChargeStation(props.chargePointId, {
                              showExternal: !e.target.checked,
                            })
                              .then(() => {
                                loadDataTable();
                                toast.success(t('ChargeStations.toastSucces'));
                              })
                              .catch(() =>
                                toast.error(t('ChargeStations.toastErr'))
                              );
                          }
                        });
                      } else {
                        MySwal.fire({
                          title: t(
                            'ChargeStations.doYouWanttoTurnOnitsVisibilityonTheMap'
                          ),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            editChargeStation(props.chargePointId, {
                              showExternal: !e.target.checked,
                            })
                              .then(() => {
                                loadDataTable();
                                toast.success(t('ChargeStations.toastSucces'));
                              })
                              .catch(() =>
                                toast.error(t('ChargeStations.toastErr'))
                              );
                          }
                        });
                      }
                    }}
                    className='switch-danger'
                  />
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.action'),
            cell: (row: any) => {
              return (
                <div>
                  <Button
                    className='px-1'
                    outline
                    cursor='pointer'
                    color='flat-primary'
                    style={{ color: '#7d8898' }}
                    // checkDisable(row)
                    disabled={checkDisable(row?.connectors)}
                    onClick={() => {
                      editContext.SetEditChargeStation(row);
                      setisOpenEdit(true);
                    }}
                  >
                    <Icons.Edit3 aria-disabled />
                  </Button>
                  <Icons.Trash2
                    color='#f00'
                    className='mr-1'
                    cursor='pointer'
                    onClick={(e) => {
                      MySwal.fire({
                        title: t(
                          'ChargeStations.doyouwanttoDeleteThisChargeStation'
                        ),
                        showConfirmButton: true,
                        showCancelButton: true,
                        cancelButtonText: t('no'),
                        confirmButtonText: t('yes'),
                        // eslint-disable-next-line consistent-return
                      }).then((result) => {
                        if (result.isConfirmed) {
                          return deleteChargeStation(row?.chargePointId)
                            .then((res: any) => {
                              if (res.isSuccess === true) {
                                loadDataTable();
                                toast.success(
                                  t('ChargeStations.deleteProcessSuccessful')
                                );
                              } else {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                // eslint-disable-next-line no-lonely-if
                                if (
                                  res.isSuccess === false &&
                                  res.message === 'CPBUSY'
                                ) {
                                  toast.error(t('ChargeStations.busy'));
                                } else {
                                  toast.error(
                                    t('ChargeStations.deleteProcessFail')
                                  );
                                }
                              }
                            })
                            .catch((err) => {
                              if (err.response.data.error.code === 'HASTRX') {
                                toast.error(
                                  t('ChargeStations.hasTransactionsError')
                                );
                              }
                            });
                        }
                      });
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={search(data) || []}
      />
      {editContext.editChargeStation && (
        <ChargeStationsDetails
          isOpen={isOpen}
          toggleHandle={() => setIsOpen(false)}
        />
      )}

      <EditChargeStation
        isOpen={isOpenEdit}
        toggleHandle={() => {
          loadDataTable();
          setisOpenEdit(false);
        }}
      />

      <AddChargeStation
        isOpen={addIsOpen}
        toggleHandle={() => {
          loadDataTable();
          setAddIsOpen(false);
        }}
      />
    </Container>
  );
};
export default ChargeStations;
