/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';

// import Scss
import './assets/scss/themes.scss';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css'; // for ES6 modules

// imoprt Route
import Route from './Routes';

import {
  DOMAIN_KEY,
  ASSET_BASE_URL,
  IS_TENANT_DISABLED,
} from './helpers/config';

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const App = () => {
  const [isDisabled, setIsDisabled] = useState(IS_TENANT_DISABLED);
  const [redirectURL, setRedirectURL] = useState<string>('');

  const handleRedirectUrl = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const, @typescript-eslint/naming-convention
    let [_, env] = window.location.hostname.split('.');
    if (env === 'uat' || env === 'dev') {
      setRedirectURL(`https://${DOMAIN_KEY}.${env ? `${env}.` : ''}lahar.io`);
    } else {
      setRedirectURL(`https://${DOMAIN_KEY}.v2.electroop.io`);
    }
  };

  useEffect(() => {
    document.title = capitalize(DOMAIN_KEY);
    const favicon: any = document.getElementById('favicon');
    if (favicon !== null) {
      favicon.href = `${ASSET_BASE_URL}favicon.png`;
    }
    handleRedirectUrl();
  }, []);
  return (
    <>
      {isDisabled ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            This portal is disabled.
            <a
              href={redirectURL}
              style={{
                marginLeft: '5px',
                textDecoration: 'none',
                color: 'blue',
              }}
            >
              Go to V2 Portal
            </a>
          </p>
        </div>
      ) : (
        <>
          <Route />
          <ToastContainer autoClose={2000} />
        </>
      )}
    </>
  );
};

export default App;


