import React, { useContext, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { ChevronDown, ChevronRight } from 'react-feather';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import DataContext from 'Components/Contexts/DataContext';
import applicationContext from 'Components/Contexts/ApplicationContext';
import { ApplicationsType } from 'declerations/DefaultTypes';

const SelectedApplication = () => {
  const [isCpo, setisCpo] = useState(false);
  const { t } = useTranslation();
  const [isCSPage, setIsCSPage] = useState(false);
  const [isCSDetailPage, setisCSDetailPage] = useState(false);
  const [isTransactionsPage, setIsTransactionsPage] = useState(false);
  const [selectedAplicationDropdown, setselectedAplicationDropdown] =
    useState(false);
  const context = useContext(DataContext);
  const Applicationcontext = useContext(applicationContext);
  const [chevronDown, setChevronDown] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setselectedAplicationDropdown(!selectedAplicationDropdown);
    setChevronDown(!chevronDown);
  };

  useEffect(() => {
    context.LoadApplications();
    const cpoIncluded = location.pathname.includes('cpo/');
    const csPageIncluded = location.pathname.includes('cpo/charge-station');
    const csDetailPageIncluded = location.pathname.includes(
      'cpo/charge-station/'
    );
    const transactionPageIncluded =
      location.pathname.includes('cpo/transaction');

    const applicationId = localStorage.getItem('applicationId');
    const locAllCPO = localStorage.getItem('allCPO');

    if (transactionPageIncluded) {
      setIsTransactionsPage(true);
      localStorage.setItem('allCPO', 'true');
      Applicationcontext.SelectApplication(null);
      setIsCSPage(false);
      setisCpo(true);
      setisCSDetailPage(false);
    }

    if (csPageIncluded) {
      setIsCSPage(true);
      setIsTransactionsPage(false);
    } else {
      setIsCSPage(false);
      if (Applicationcontext.selectedApplication === null) {
        Applicationcontext.SelectApplication(
          context.applications.find((x: any) => x._id === applicationId) ||
            context.applications[0]
        );
      }
    }
    if (cpoIncluded) {
      setisCpo(true);
    } else {
      setisCpo(false);
    }
    if (csDetailPageIncluded) {
      setisCSDetailPage(true);
    } else {
      setisCSDetailPage(false);
    }
  }, [location]);

  useEffect(() => {
    const csPageIncluded = location.pathname.includes('cpo/charge-station');
    if (csPageIncluded) {
      setIsCSPage(true);
      if (Applicationcontext.selectedApplication === null) {
        localStorage.setItem('allCPO', 'true');
      } else {
        localStorage.setItem('allCPO', 'false');
      }
    } else {
      setIsCSPage(false);
    }
  }, [Applicationcontext.selectedApplication]);

  useEffect(() => {
    const transactionPageIncluded =
      location.pathname.includes('cpo/transaction');

    if (transactionPageIncluded) {
      setIsTransactionsPage(true);
      if (Applicationcontext.selectedApplication === null) {
        localStorage.setItem('allCPO', 'true');
      } else {
        localStorage.setItem('allCPO', 'false');
      }
    } else {
      setIsTransactionsPage(false);
    }
  }, [Applicationcontext.selectedApplication]);

  const all = { _id: 'null', name: t('Transaction.allCPO') };

  return context.applications &&
    isCpo &&
    !isCSDetailPage &&
    context.applications.length > 0 ? (
    <Dropdown
      isOpen={selectedAplicationDropdown}
      toggle={toggleDropdown}
      className='ms-1 topbar-head-dropdown header-item'
    >
      <DropdownToggle className='btn btn-primary me-2 ' tag='button'>
        {Applicationcontext.selectedApplication?.name ||
          t('Transaction.allCPO')}{' '}
        {chevronDown === false ? <ChevronDown /> : <ChevronRight />}
      </DropdownToggle>
      <DropdownMenu
        className='notify-item language py-2'
        style={{ overflow: 'auto', maxHeight: 345 }}
      >
        {/* <DropdownItem
          onClick={() => Applicationcontext.SelectApplication(all)}
          className={`notify-item ${
            Applicationcontext.selectedApplication?._id === all._id
              ? 'active'
              : 'none'
          }`}
        >
          <span className='align-middle'>{all.name}</span> 
        </DropdownItem> */}
        {isTransactionsPage && (
          <DropdownItem
            onClick={() => Applicationcontext.SelectApplication(null)}
            className={`notify-item ${
              Applicationcontext.selectedApplication?._id === ''
                ? 'active'
                : 'none'
            }`}
          >
            <span className='align-middle'>{t('Transaction.allCPO')}</span>
          </DropdownItem>
        )}
        {isCSPage && (
          <DropdownItem
            onClick={() => Applicationcontext.SelectApplication(null)}
            className={`notify-item ${
              Applicationcontext.selectedApplication?._id === null
                ? 'active'
                : 'none'
            }`}
          >
            <span className='align-middle'>{t('Transaction.allCPO')}</span>
          </DropdownItem>
        )}
        {context.applications.map(
          (application: ApplicationsType, index: number) => (
            <DropdownItem
              key={index.toString()}
              onClick={() => {
                Applicationcontext.SelectApplication(application);
                localStorage.setItem('applicationId', application._id);
              }}
              className={`notify-item ${
                Applicationcontext.selectedApplication?._id === application._id
                  ? 'active'
                  : 'none'
              }`}
            >
              <span className='align-middle'>{application.name}</span>
            </DropdownItem>
          )
        )}
      </DropdownMenu>
    </Dropdown>
  ) : (
    <div />
  );
};

export default SelectedApplication;
