/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-else-return */

import React, { useEffect, useState, useContext, useRef } from 'react';

import {
  Col,
  Row,
  FormGroup,
  Badge,
  AccordionItem,
  AccordionBody,
  Accordion,
} from 'reactstrap';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import EditContext from 'Components/Contexts/EditContext';
import DriverDetail from 'pages/CRM/Drivers/DriverDetail/DriverDetail';
import { useHistory } from 'react-router-dom';
import { getLabelCurrency } from 'helpers/Currencies';
import SearchInput from 'Components/Common/SearchInput';
import SelectBox from 'Components/Common/SelectBox';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import ExportBlock from 'laxyExcel/ExportComponents';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { getDriverById, getTransactions } from 'actions';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { handleTransactionStatus } from 'helpers/Transaction';
import TransactionStatusBadge from 'Components/Common/TransactionStatusBadge';
import StatsCards from './StatsCards';
import {
  TransactionType,
  DriverType,
  LocationType,
  ChargeStationType,
} from '../../declerations/DefaultTypes';
import TabSection from './SlidingModal/TabSection';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import i18n from '../../i18n';

enum TransactionStatusFilterEnum {
  ALL = 'null',
  COMPLETED = 'Completed',
  PAID = 'Paid',
  UNPAID = 'Unpaid',
  FREE = 'Free',
  NOT_CHARGED = 'NotCharged',
  CHARGING = 'Charging',
  REFUNDED = 'Refunded',
}

const Transactions = ({ ocppTagId }: { ocppTagId?: string | undefined }) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const [data, setData] = useState<TransactionType[] | undefined>();
  const [count, setCount] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [PerRows, setPerRows] = useState<number>(100);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const applicationContext = useContext(ApplicationContext);
  const [filter, setFilter] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
    applicationId: string | undefined;
    locationId: string | undefined;
    chargePointId: string | undefined;
    state: string | undefined;
    user: string | undefined;
    plate: string | undefined;
    ocppTagId: string | undefined;
    limit: number | undefined;
    offset: number | undefined;
  }>({
    applicationId: applicationContext.selectedApplication?._id || 'null',
    locationId: 'null',
    chargePointId: 'null',
    state: 'null',
    user: 'null',
    plate: 'null',
    endDate: 'null',
    startDate: 'null',
    ocppTagId: 'null',
    limit: PerRows,
    offset: 0,
  });
  const [filtered, setFiltered] = useState('');
  const [selectedPayment, setSelectedPayment] = useState<any>();
  const [selectedRangeType, setSelectedRangeType] = useState('all');
  const [rangeDate, setRangeDate] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpened, setfilterOpened] = useState('1');
  const [isDriverDetailOpen, setisDriverDetailOpen] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState(undefined);
  const refApplication = useRef<string>();
  const refLocation = useRef<string>();
  const refChargePoint = useRef<string>();
  const refStartDate = useRef<string>();
  const refOcppTagId = useRef<string>();
  const refState = useRef<string>();
  const refEndDate = useRef<string>();
  const refUser = useRef<string>();

  const loadDataTable = () => {
    const stableFilter = {
      ...filter,
      applicationId: isFirstLoad
        ? 'null'
        : applicationContext.selectedApplication?._id,
    };
    if (filter.applicationId !== 'null') {
      if (
        refApplication.current === filter.applicationId &&
        refLocation.current === filter.locationId &&
        refChargePoint.current === filter.chargePointId
      ) {
        getTransactions(stableFilter)
          .then((res) => {
            setData(res.data.transactions);
            setCount(res.data.count);
          })
          .then(() => {
            context.LoadDrivers();
          });
      } else {
        getTransactions(stableFilter)
          .then((res) => {
            setData(res.data.transactions);
            setCount(res.data.count);
          })
          .then(() => {
            context.LoadLocations(stableFilter);
          })
          .then(() => {
            context.LoadChargeStations(stableFilter);
            setLoading(false);
          })
          .then(() => {
            context.LoadDrivers();
          })
          .then(() => {
            refChargePoint.current = filter.chargePointId;
            refLocation.current = filter.locationId;
            refApplication.current = filter.applicationId;
            refUser.current = filter.user;
          });
      }
    }

    if (isFirstLoad) setIsFirstLoad(false);
  };

  useEffect(() => {
    loadDataTable();
  }, [filter]);

  useEffect(() => {
    if (isFirstLoad) return;
    setSelectedApplication((prev) => {
      if (prev !== applicationContext.selectedApplication?._id) {
        return applicationContext.selectedApplication?._id;
      }
      return prev;
    });
  }, [applicationContext.selectedApplication]);

  useEffect(() => {
    if (isOpen) {
      setIsActiveAutoRefresh(false);
    } else {
      setIsActiveAutoRefresh(true);
    }
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, filter]);

  useEffect(() => {
    if (isOpen) {
      setIsActiveAutoRefresh(false);
    } else {
      setIsActiveAutoRefresh(true);
    }
  }, [isOpen]);

  const search = (rowData: TransactionType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: TransactionType) =>
        row?.chargePointId?.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1 ||
        row?.connectorSocketType
          ?.toLowerCase()
          .indexOf(filtered.toLowerCase()) > -1 ||
        (row.consumer &&
          `${row?.consumer?.fname?.toLowerCase()} ${row?.consumer?.lname?.toLowerCase()}`.indexOf(
            filtered.toLowerCase()
          ) > -1) ||
        (row.consumer &&
          row?.consumer?.email?.toLowerCase().indexOf(filtered.toLowerCase()) >
            -1) ||
        (row.idTag &&
          row?.idTag?.toLowerCase().indexOf(filtered.toLowerCase()) > -1) ||
        (row.transactionId &&
          row?.transactionId
            ?.toString()
            .toLowerCase()
            .indexOf(filtered.toLowerCase()) > -1) ||
        (row?.idTagName &&
          row?.idTagName?.toLowerCase().indexOf(filtered.toLowerCase()) > -1) ||
        (row.consumer?.vehicle?.plateNo &&
          row.consumer?.vehicle?.plateNo
            ?.toString()
            .toLowerCase()
            .indexOf(filtered.toLowerCase()) > -1)
    );
  };
  const changeSelectedRangeType = (type: string) => {
    const baseDate = new Date();
    let startDate: Date | string = new Date();
    let endDate: Date | string = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() + 1
    );
    if (type === 'today') {
      startDate.setHours(0, 0, 0, 0);
      endDate = moment(rangeDate[1]).toDate();
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisWeek') {
      startDate = new Date(
        baseDate.setDate(baseDate.getDate() - baseDate.getDay())
      );
      startDate.setHours(0, 0, 0, 0);
      endDate = moment().endOf('week').add(1, 'days').toDate();
      endDate.setHours(23, 59, 59, 999);
      const startDateDay = startDate.toLocaleDateString('en-US', {
        weekday: 'long',
      });

      if (startDateDay === 'Sunday') {
        startDate = moment(startDate).add(1, 'days').toDate();
        endDate = moment(endDate).add(-1, 'days').toDate();
      }

      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisMonth') {
      startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisYear') {
      startDate = new Date(baseDate.getFullYear(), 0, 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'customDate') {
      startDate = moment(rangeDate[0]).toDate();
      endDate = moment(rangeDate[1]).toDate();
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'all') {
      setFilter({ ...filter, endDate: 'null', startDate: 'null' });
    }
    setSelectedRangeType(type);
  };

  useEffect(() => {
    if (selectedRangeType === 'customDate') {
      changeSelectedRangeType('customDate');
    }
  }, [rangeDate]);

  const changeFilter = (filterType: string, value: string) => {
    switch (filterType) {
      case 'applicationId':
        setFilter({
          ...filter,
          applicationId: value,
          locationId: 'null',
          chargePointId: 'null',
          ocppTagId: 'null',
          limit: PerRows,
          offset: 0,
        });
        setReset(!reset);

        break;
      case 'locationId':
        setFilter({
          ...filter,
          locationId: value,
          chargePointId: 'null',
          limit: PerRows,
          offset: 0,
        });
        setReset(!reset);
        break;
      case 'chargePointId':
        setFilter({
          ...filter,
          chargePointId: value,
          limit: PerRows,
          offset: 0,
        });
        setReset(!reset);
        break;
      case 'user':
        setFilter({ ...filter, user: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      case 'state':
        setFilter({ ...filter, state: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      case 'ocppTagId':
        setFilter({ ...filter, ocppTagId: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      case 'plate':
        setFilter({ ...filter, plate: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isFirstLoad) return;
    changeFilter('applicationId', applicationContext.selectedApplication?._id);
  }, [selectedApplication]);

  const findDriver = (transaction: TransactionType) => {
    const consumer = context.drivers?.find(
      (x: DriverType) => x.ocppTagId === transaction.idTag
    );
    if (!consumer) {
      return null;
    }
    return `${consumer.fname} ${consumer.lname}`;
  };

  const mappers = {
    status: (row: any) => {
      if (row.isRefunded) {
        return t('Transaction.refunded');
      }
      if (row.inProgress) {
        return t('Transaction.inProgress');
      }
      if (row.paymentProfile?.paymentStatus === 'FAILED') {
        return t('Transaction.notPaid');
      }
      return t('Transaction.paid');
    },
    driver: (row: any) => {
      return findDriver(row);
    },
    transferredEnergy: (row: any) => {
      if (row?.paymentProfile?.powerConsumptionInKWh) {
        return `${row?.paymentProfile?.powerConsumptionInKWh} kWh`;
      }
      return `-`;
    },
    endDate: (row: any) => {
      if (row.inProgress) {
        return '-';
      } else {
        if (row?.timezone) {
          if (i18n.language === 'en') {
            return `${moment(row?.endDate)
              .tz(row?.timezone)
              .format('lll')
              .toString()}`;
          } else {
            return `${moment(row?.endDate)
              .tz(row?.timezone)
              .locale('tr')
              .format('lll')
              .toString()}`;
          }
        }
        if (i18n.language === 'en') {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          `${moment(row.endDate).format('lll').toString()}`;
        }
        return `${moment(row.endDate).locale('tr').format('lll').toString()}`;
      }
    },
    startDate: (row: any) => {
      if (row?.timezone) {
        if (i18n.language === 'en') {
          return `${moment(row?.startDate)
            .tz(row?.timezone)
            .format('lll')
            .toString()}`;
        } else {
          return `${moment(row?.startDate)
            .tz(row?.timezone)
            .locale('tr')
            .format('lll')
            .toString()}`;
        }
      }
      if (i18n.language === 'en') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        `${moment(row.startDate).format('lll').toString()}`;
      }
      return `${moment(row.startDate).locale('tr').format('lll').toString()}`;
    },
  };
  const headers = [
    'paymentProfile.paymentId',
    'transactionId',
    '_id',
    'application.code',
    'consumer._id',
    'consumer.email',
    'consumer.phone',
    'consumer.address.address',
    'consumer.address.city',
    'consumer.address.country',
    'consumer.invoiceType',
    'consumer.consumerType',
    'consumer.taxCity',
    'consumer.taxOffice',
    'consumer.taxNumber',
    'consumer.companyName',
    'consumer.identityNumber',
    'consumer.fname',
    'consumer.lname',
    'consumer.vehicle.plateNo',
    'startDate',
    'endDate',
    'chargePointId',
    'application.currency',
    'paymentProfile.parkingFee',
    'paymentProfile.parkingDurationInMins',
    'paymentProfile.powerConsumptionInKWh',
    'paymentProfile.durationInMins',
    'paymentProfile.energyCost',
    'paymentProfile.energyTariff.ratePerKWh',
    'paymentProfile.totalFee',
    'paymentProfile.paymentStatus',
    'chargePointDbId',
    'connectorSocketType',
    'connectorType',
    'connectorId',
    'cpLocation.title',
  ].map((key: string) => ({
    key,
    value: `transactions.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));

  const pagination = (e: number) => {
    setPageNumber(e);
    const control = count / PerRows;
    const flag = Math.ceil(control);
    if (e <= flag) {
      setFilter({
        ...filter,
        offset: PerRows && (e - 1) * PerRows,
        limit: PerRows,
      });
    } else if (e === flag) {
      setFilter({
        ...filter,
        offset: count,
        limit: PerRows,
      });
    }
  };
  const handlePerRowsChange = (e: number) => {
    setPerRows(e);
    setReset(!reset);
    setFilter({
      ...filter,
      offset:
        PerRows && pageNumber === 0 ? pageNumber * e : (pageNumber - 1) * e,
      limit: e,
    });
  };

  const openTransactionDetail = (transaction: any) => {
    setSelectedPayment(transaction);
    editContext.SetEditTransaction(transaction);
    setIsOpen(true);
  };

  return (
    <Container
      title={ocppTagId ? '' : t('Transactions')}
      className={ocppTagId ? 'pt-4' : ''}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      {/* StatsCards */}
      {!ocppTagId && (
        <Row className='d-flex flex-wrap'>
          <Col xl={12} lg={12} sm={12} xs={12}>
            <StatsCards
              startDate={filter.startDate}
              endDate={filter.endDate}
              applicationId={selectedApplication}
              locationId={filter.locationId}
              chargePointId={filter.chargePointId}
              user={filter.user}
              ocppTagId={filter.ocppTagId}
            />
          </Col>
        </Row>
      )}
      <Row className='mb-4'>
        <Col>
          <Accordion open={filterOpened} className='v2'>
            <AccordionItem>
              <AccordionBody accordionId='1'>
                <Row>
                  <Col xl={10} sm={12}>
                    <Row>
                      <Col xl={3} sm={12}>
                        <label
                          style={{
                            color: 'rgba(0,0,0,0.87)',
                            fontWeight: '600',
                            fontSize: '14px',
                          }}
                          htmlFor='range-picker'
                        >
                          &nbsp;
                          {t('')}
                        </label>
                        <SearchInput
                          isFilter
                          onFocus={() => setfilterOpened('1')}
                          onChange={(text) => {
                            setFiltered(text);
                          }}
                        />
                      </Col>
                      <Col xl={3} sm={12}>
                        <Row>
                          <Col>
                            <FormGroup className='m-0'>
                              <label
                                style={{
                                  fontWeight: '400',
                                  fontSize: '14px',
                                }}
                                className='fw-regular'
                                htmlFor='date-range'
                              >
                                {t('Transaction.chooseDateRange')}
                              </label>
                              <select
                                id='date-range'
                                className='form-control form-select mb-1'
                                value={selectedRangeType}
                                onChange={(e) =>
                                  changeSelectedRangeType(e.target.value)
                                }
                              >
                                <option style={{ color: 'black' }} value='all'>
                                  {t('Transaction.all')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='today'
                                >
                                  {t('Transaction.today')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='thisWeek'
                                >
                                  {t('Transaction.thisWeek')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='thisMonth'
                                >
                                  {t('Transaction.thisMonth')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='thisYear'
                                >
                                  {t('Transaction.thisYear')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='customDate'
                                >
                                  {t('Transaction.customDate')}
                                </option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      {selectedRangeType === 'customDate' && (
                        <Col xl={3} sm={12} xs={12}>
                          <Row>
                            <Col xl={12} sm={12} className='text-align'>
                              <FormGroup className='m-0'>
                                <label
                                  style={{
                                    fontWeight: '400',
                                    fontSize: '14px',
                                  }}
                                  htmlFor='range-picker'
                                >
                                  {t('Transaction.chooseDateRange')}
                                </label>
                                <Flatpickr
                                  value={rangeDate}
                                  id='range-picker'
                                  className='form-control'
                                  onChange={(date) => {
                                    if (date.length > 0) {
                                      setRangeDate(date);
                                    }
                                  }}
                                  options={{
                                    mode: 'range',
                                    maxDate: new Date(),
                                    locale:
                                      t('Transaction.locale') === 'Turkish'
                                        ? Turkish
                                        : undefined,
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      <Col xl={3} sm={12} xs={12}>
                        <SelectBox
                          value={filter.locationId}
                          label={t('Transaction.location')}
                          onChange={(value) =>
                            changeFilter('locationId', value)
                          }
                        >
                          <option value='null'>{t('all')}</option>
                          {context.locations &&
                            context.locations.length > 0 &&
                            context.locations.map((item: LocationType) => (
                              <option key={item._id} value={item._id}>
                                {item.title}
                              </option>
                            ))}
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12} xs={12}>
                        <SelectBox
                          value={filter.chargePointId}
                          label={t('Transaction.chargePoint')}
                          onChange={(value) =>
                            changeFilter('chargePointId', value)
                          }
                        >
                          <option value='null'>{t('all')}</option>
                          {context.chargeStations &&
                            context.chargeStations.length > 0 &&
                            context.chargeStations.map(
                              (item: ChargeStationType) => (
                                <option
                                  key={item.chargePointId}
                                  value={item.chargePointId}
                                >
                                  {item.name}
                                </option>
                              )
                            )}
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12} xs={12}>
                        <SelectBox
                          value={filter.user}
                          label={t('Transaction.startedBy')}
                          onChange={(value) => changeFilter('user', value)}
                        >
                          <option value='null'>{t('all')}</option>
                          <option value='Customer'>
                            {t('Transaction.customer')}
                          </option>
                          <option value='CPO'>{t('Transaction.Cpo')}</option>
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12}>
                        <SelectBox
                          value={filter.state}
                          label={t('ChargeStations.state')}
                          onChange={(value) => changeFilter('state', value)}
                        >
                          <option value={TransactionStatusFilterEnum.ALL}>
                            {t('all')}
                          </option>
                          <option value={TransactionStatusFilterEnum.CHARGING}>
                            {t('Transaction.charging')}
                          </option>
                          <option value={TransactionStatusFilterEnum.COMPLETED}>
                            {t('Transaction.completed')}
                          </option>
                          <option value={TransactionStatusFilterEnum.PAID}>
                            {t('Transaction.paid')}
                          </option>
                          <option value={TransactionStatusFilterEnum.UNPAID}>
                            {t('Transaction.unpaid')}
                          </option>
                          <option value={TransactionStatusFilterEnum.REFUNDED}>
                            {t('Transaction.refunded')}
                          </option>
                          <option value={TransactionStatusFilterEnum.FREE}>
                            {t('Transaction.free')}
                          </option>
                          <option
                            value={TransactionStatusFilterEnum.NOT_CHARGED}
                          >
                            {t('Transaction.notCharged')}
                          </option>
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12}>
                        <SelectBox
                          value={filter.ocppTagId}
                          label={t('Authorization')}
                          onChange={(value) => changeFilter('ocppTagId', value)}
                        >
                          <option value='null'>{t('all')}</option>
                          {context.ocppTags &&
                            context.ocppTags.length > 0 &&
                            context.ocppTags.map((item: any) => (
                              <option
                                key={item.chargePointId}
                                value={item.idTag}
                              >
                                {item.tagName || item.idTag}
                              </option>
                            ))}
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12}>
                        <label
                          style={{
                            color: '#7D8898',
                            fontWeight: '400',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            lineHeight: '16px',
                          }}
                          htmlFor='plate'
                        >
                          &nbsp;
                          {t('plateNo')}
                        </label>
                        <SearchInput
                          onChange={(value) => changeFilter('plate', value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={2} sm={12}>
                    <br />
                    <div
                      className='flex justify-content-end'
                      style={{ float: 'right', marginTop: 2 }}
                    >
                      <ExportBlock
                        data={data}
                        header={headers}
                        name={t('Transactions')}
                        pdfExportDisabled
                      />
                    </div>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} sm={12}>
          <DataTable
            loading={loading}
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={PerRows}
            onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
            onChangePage={(e) => pagination(e)}
            paginationResetDefaultPage={reset}
            noDataText={t('Transaction.thereareNoTransactionCreated')}
            onRowClicked={(row, event) => {
              openTransactionDetail(row);
            }}
            columns={[
              {
                name: t('Transaction.status'),
                grow: 1.4,
                cell: (row: any) => {
                  const { title, color } = handleTransactionStatus(row);
                  if (row.textOnly) return t(title);
                  return (
                    <h5>
                      <TransactionStatusBadge color={color}>
                        {t(title)}
                      </TransactionStatusBadge>
                    </h5>
                  );
                },
              },
              {
                name: t('CPO'),
                selector: (row: TransactionType) => row.application.name,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) => (
                  <div
                    onClick={() => {
                      openTransactionDetail(row);
                    }}
                  >
                    <div>{row?.application?.name}</div>
                    <div>{row?.application?.code}</div>
                  </div>
                ),
              },
              {
                name: t('Transaction.startTime'),
                selector: (row: TransactionType) => row.startDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row?.timezone
                    ? i18n.language === 'en'
                      ? moment(row?.startDate)
                          .tz(row?.timezone)
                          .format('lll')
                          .toString()
                      : moment(row?.startDate)
                          .tz(row?.timezone)
                          .locale('tr')
                          .format('lll')
                          .toString()
                    : i18n.language === 'en'
                    ? moment(row.startDate).format('lll').toString()
                    : moment(row.startDate)
                        .locale('tr')
                        .format('lll')
                        .toString(),
              },
              {
                name: t('Transaction.endTime'),
                selector: (row: TransactionType) => row.endDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row.inProgress
                    ? '-'
                    : row?.timezone
                    ? i18n.language === 'en'
                      ? moment(row.endDate)
                          .tz(row.timezone)
                          .format('lll')
                          .toString()
                      : moment(row.endDate)
                          .tz(row.timezone)
                          .locale('tr')
                          .format('lll')
                          .toString()
                    : i18n.language === 'en'
                    ? moment(row.endDate).format('lll').toString()
                    : moment(row.endDate).locale('tr').format('lll').toString(),
              },
              {
                name: t('Transaction.driver'),
                selector: (row: TransactionType) => row.idTag,
                cell: (row: TransactionType) => {
                  const title =
                    row?.consumer?.fname && row?.consumer?.lname
                      ? `${row?.consumer?.fname} ${row?.consumer?.lname}`
                      : '-';
                  return (
                    <div>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href='#'
                        aria-hidden='true'
                        onClick={(e) => {
                          e.preventDefault();
                          getDriverById(row?.consumer?._id || '')
                            .then((res: AxiosResponse<TransactionType>) => {
                              if (!res.data?.consumer) {
                                toast.error(t('TransactionDetail.userDeleted'));
                                return;
                              }
                              editContext.SetEditDriver(res.data.consumer);
                              setisDriverDetailOpen(true);
                            })
                            .catch(() => {
                              toast.error(t('TransactionDetail.userDeleted'));
                            });

                          // history.push(`/drivers/${consumer._id}`, {
                          //   driver: consumer,
                          // });
                        }}
                      >
                        {title}
                      </a>
                      {row.consumer?.invoiceType && (
                        <div>
                          {t(`Transaction.${row.consumer?.invoiceType}`)}
                        </div>
                      )}
                    </div>
                  );
                },
              },
              {
                name: t('Transaction.plateNo'),
                selector: (row: any) =>
                  row.consumer?.vehicle?.plateNo
                    ? row.consumer?.vehicle?.plateNo
                    : '-',
              },
              {
                name: t('Transaction.CP Id'),
                selector: (row: TransactionType) => row.chargePointId,
              },
              {
                name: t('Transaction.transactionId'),
                selector: (row: TransactionType) => row.transactionId,
              },
              {
                name: t('Transaction.tagName'),
                selector: (row: TransactionType) => row.idTagName,
                cell: (row: TransactionType) =>
                  row?.idTagName != null ? row.idTagName : row.idTag,
              },
              {
                name: t('Transaction.totalEnergy'),
                selector: (row: TransactionType) =>
                  row.paymentProfile.powerConsumptionInKWh,
                cell: (row: TransactionType) =>
                  row.paymentProfile.powerConsumptionInKWh
                    ? `${row.paymentProfile.powerConsumptionInKWh.toFixed(
                        2
                      )} kWh`
                    : `0 kWh`,
              },
              {
                name: t('Transaction.totalFee'),
                sortable: true,
                selector: (row: TransactionType) => row.paymentProfile.totalFee,
                cell: (row: TransactionType) => (
                  <div className='text-primary'>
                    {row.paymentProfile && row.paymentProfile.totalFee
                      ? `${row.paymentProfile.totalFee.toFixed(
                          2
                        )} ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`
                      : `0 ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`}
                  </div>
                ),
              },
            ]}
            data={search(data) || []}
          />
        </Col>
      </Row>
      {isOpen && (
        <TabSection
          isOpen={isOpen}
          toggleHandle={() => setIsOpen(false)}
          selectedPayment={selectedPayment}
        />
      )}
      <DriverDetail
        isOpen={isDriverDetailOpen}
        toggleHandle={() => {
          context.LoadTransactions(filter);
          setisDriverDetailOpen(false);
        }}
      />
    </Container>
  );
};

Transactions.defaultProps = {
  ocppTagId: undefined,
};
export default Transactions;
