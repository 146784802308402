import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
  renderField,
  renderSelectField,
  renderTextArea,
} from 'Components/Common/Fields';
import { Row, Col, Button, Spinner } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import i18n from 'i18n';
import ModalForm from 'Components/Common/ModalForm';
import { useTranslation } from 'react-i18next';
import DataContext from 'Components/Contexts/DataContext';
import EditContext from 'Components/Contexts/EditContext';
import { editChargeStation } from 'actions';
import SlidingModal from 'Components/Common/SlidingModal';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import { ApplicationsType, LocationType } from 'declerations/DefaultTypes';
import ChooseFromMap from './ChooseFromMap';

function myXor(...args: any[]) {
  console.log(args.filter((v) => !!v).length);
  return (
    args.filter((v) => !!v).length >= 1 &&
    args.filter((v) => !!v).length !== args.length
  );
}

const formSchema = () =>
  Yup.object().shape({
    applicationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    appLocationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    registrationStatus: Yup.string()
      .min(1, 'Required')
      .required(i18n.t('Required')),

    longitude: Yup.number().test(
      'longitude',
      i18n.t('Required'),
      function (value) {
        const { latitude } = this.parent;
        if (myXor(latitude, value) && !value) {
          return false;
        }
        return true;
      }
    ),

    latitude: Yup.number().test(
      'required',
      i18n.t('Required'),
      function (value) {
        const { longitude } = this.parent;
        if (myXor(longitude, value) && !value) {
          return false;
        }
        return true;
      }
    ),
    address: Yup.string().test(
      'required',
      i18n.t('Required'),
      function (value) {
        const { city, country, zipcode, address } = this.parent;
        if (myXor(city, country, zipcode, address) && !value) {
          return false;
        }
        return true;
      }
    ),
    city: Yup.string().test('required', i18n.t('Required'), function (value) {
      const { city, country, zipcode, address } = this.parent;
      if (myXor(city, country, zipcode, address) && !value) {
        return false;
      }
      return true;
    }),
    country: Yup.string().test(
      'required',
      i18n.t('Required'),
      function (value) {
        const { city, country, zipcode, address } = this.parent;
        if (myXor(city, country, zipcode, address) && !value) {
          return false;
        }
        return true;
      }
    ),
    county: Yup.string().required(i18n.t('Required')),
    cityCode: Yup.string()
      .max(2, i18n.t('AddChargeStations.max2Characters'))
      .matches(/^\d+$/, i18n.t('AddChargeStations.onlyNumber'))
      .required(i18n.t('Required')),
    zipcode: Yup.string().test(
      'required',
      i18n.t('Required'),
      function (value) {
        const { city, country, zipcode, address } = this.parent;
        if (myXor(city, country, zipcode, address) && !value) {
          return false;
        }
        return true;
      }
    ),
    name: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    heartbeatInterval: Yup.number()
      .integer(i18n.t('ChargeStations.valueMustBeInteger'))
      .min(0, i18n.t('ChargingRate.higherThanZero'))
      .required(i18n.t('Required')),
  });

interface EditChargeStationProps {
  isOpen: boolean;
  toggleHandle: () => void;
}

const EditChargeStation = ({
  isOpen,
  toggleHandle,
}: EditChargeStationProps) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const applicationContext = useContext(ApplicationContext);

  const [chooseFromMapModal, setChooseFromMapModal] = useState(false);
  const editContext = useContext(EditContext);
  const [initialValues, setInitialValues] = useState({
    applicationId: '',
    registrationStatus: '',
    name: '',
    longitude: 0,
    latitude: 0,
    address: '',
    city: '',
    country: '',
    county: '',
    cityCode: '',
    zipcode: '',
    appLocationId: '',
    heartbeatInterval: '',
    chargePointId: '',
  });

  useEffect(() => {
    if (!editContext.editChargeStation) return;
    const {
      applicationId = '',
      registrationStatus = 'Accepted',
      name = '',
      heartbeatInterval = '',
      location = [],
      address: addressObj = {},
      appLocation = {},
      application = {},
      chargePointId = '',
    } = editContext.editChargeStation;
    // const { _id: applicationId = '' } = application || {};
    const { _id: appLocationId = '' } = appLocation || {};
    const {
      address = '',
      city = '',
      country = '',
      county = '',
      cityCode = '',
      zipcode = '',
    } = addressObj || {};

    setInitialValues({
      applicationId,
      registrationStatus,
      name,
      longitude: location.length > 0 ? location[0] : '',
      latitude: location.length > 1 ? location[1] : '',
      address,
      city,
      country,
      county,
      cityCode,
      zipcode,
      appLocationId,
      heartbeatInterval,
      chargePointId,
    });
    setLoading(false);
  }, [editContext.editChargeStation]);

  useEffect(() => {
    if (!isOpen) return;
    context.LoadApplications({
      applicationId: applicationContext.selectedApplication?._id,
    });
    context.LoadLocations({
      applicationId: applicationContext.selectedApplication?._id,
    });
  }, [isOpen]);

  const toggleChooseFromModal = () => {
    setChooseFromMapModal(!chooseFromMapModal);
  };
  const { applications, locations } = context;

  return (
    <SlidingModal
      title={t('ChargeStations.editChargeStation')}
      size='md'
      isOpen={isOpen}
      toggleHandle={toggleHandle}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            const { applicationId, chargePointId, ...newValues } = values;
            editChargeStation(chargePointId, newValues)
              .then((response) => {
                toast.success(t('ChargeStations.toastSucces'));
                return toggleHandle();
              })
              .catch((err) => toast.error(t('ChargeStations.toastErr')));
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            touched,
            errors,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setFieldTouched,
          }) => {
            useMemo(
              () =>
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                }),
              [i18n.language]
            );
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='name'
                      label={t('AddChargeStations.name')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.name,
                        error: errors.name,
                      }}
                    />
                  </Col>
                </Row>

                <Row className='mt-2'>
                  <Col>
                    <Field
                      component={renderSelectField}
                      name='registrationStatus'
                      label={t('AddChargeStations.registrationStatus*')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.registrationStatus,
                        error:
                          errors?.registrationStatus &&
                          t(errors.registrationStatus),
                      }}
                    >
                      <option value='Accepted'>
                        {t('AddChargeStations.accepted')}
                      </option>
                      <option value='Pending'>
                        {t('AddChargeStations.pending')}
                      </option>
                      <option value='Rejected'>
                        {t('AddChargeStations.rejected')}
                      </option>
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='heartbeatInterval'
                      label={t('AddChargeStations.heartbeatInterval')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.heartbeatInterval,
                        error: errors && errors.heartbeatInterval,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label htmlFor='location'>
                      {t('AddChargeStations.location')}
                    </label>
                    <Select
                      options={locations}
                      value={
                        locations &&
                        locations.find(
                          (x: LocationType) => x._id === values.appLocationId
                        )
                      }
                      getOptionLabel={(x) => x.title}
                      getOptionValue={(x) => x._id}
                      placeholder={t('Select')}
                      onChange={(data) => {
                        setFieldValue('appLocationId', data._id);
                      }}
                    />
                    <ErrorMessage
                      name='appLocationId'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col xl={6} md={12} className='='>
                    <h5>{t('AddChargeStations.mapLocation')}</h5>
                  </Col>
                </Row>
                <Row className='mb-2'>
                  <Col xl={6} md={12}>
                    <Button
                      onClick={toggleChooseFromModal}
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      className='me-1 mt-1'
                    >
                      {t('AddChargeStations.chooseFromMap')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='longitude'
                      label={t('AddChargeStations.longitude')}
                      className='form-control'
                      type='number'
                      meta={{
                        touched: touched.longitude,
                        error: errors.longitude,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='latitude'
                      label={t('AddChargeStations.latitude')}
                      className='form-control'
                      type='number'
                      meta={{
                        touched: touched.latitude,
                        error: errors.latitude,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='country'
                      label={t('AddChargeStations.country')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.country,
                        error: errors.country,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='city'
                      label={t('AddChargeStations.city')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.city,
                        error: errors.city,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='county'
                      label={t('AddChargeStations.county')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.county,
                        error: errors.county,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='cityCode'
                      label={t('AddChargeStations.cityCode')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.cityCode,
                        error: errors.cityCode,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='zipcode'
                      label={t('AddChargeStations.zipcode')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.zipcode,
                        error: errors.zipcode,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderTextArea}
                      name='address'
                      label={t('AddChargeStations.address')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.address,
                        error: errors.address,
                      }}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddChargeStations.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {t('AddChargeStations.edit')}
                    </Button>
                  </Col>
                </Row>
                {chooseFromMapModal && (
                  <ChooseFromMap
                    toggleModal={toggleChooseFromModal}
                    isOpen={chooseFromMapModal}
                    changeLatLong={(lat: number, long: number) => {
                      setFieldValue('latitude', lat);
                      setFieldValue('longitude', long);
                    }}
                    changeAddress={(
                      address: string,
                      city: string,
                      country: string,
                      zipcode: string,
                      county: string,
                      cityCode: string
                    ) => {
                      setFieldValue('address', address);
                      setFieldValue('city', city);
                      setFieldValue('country', country);
                      setFieldValue('county', county);
                      setFieldValue('cityCode', cityCode);
                      setFieldValue('zipcode', zipcode);
                    }}
                    lat={values.latitude}
                    long={values.longitude}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </SlidingModal>
  );
};
export default EditChargeStation;
